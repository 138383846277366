@tailwind base;
@tailwind components;
@tailwind utilities;

/* !!!!!!!
    GOOGLE FONTS
!!!!!!! */

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap");

/* !!!!!!!
    GLOBAL
!!!!!!! */

* {
  -webkit-tap-highlight-color: transparent;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #1a1f2c;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Lato", "Poppins",
    "Roboto Condensed", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

/* !!!!!!!
    NAVBAR
!!!!!!! */

.navbar::before {
  content: "";
  background-image: url("https://piotr.rzadkowolski.dev/assets/hexagonBg.svg");
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.1;
}

.indicator {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.indicator::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -22px;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-top-right-radius: 20px;
  box-shadow: 0px -10px 0 0 #1a1f2c;
}

.indicator::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -22px;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-top-left-radius: 20px;
}

/* !!!!!!!
    ARROW BOUNCE
!!!!!!! */

.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
    opacity: 1;
  }
  40% {
    transform: translateY(-30px);
    opacity: 0;
  }
  60% {
    transform: translateY(-15px);
  }
}

.portfolio::before {
  content: "";
  background-image: url("https://piotr.rzadkowolski.dev/assets/boardBg.svg");
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.1;
}
